import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

function JobsPage() {
  const data = useStaticQuery(graphql`
    {
      jobs: allContentfulJob(filter: { active: { eq: true } }) {
        nodes {
          id
          title
          jobLocation
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO
        keywords={[
          `livestock`,
          `breeding`,
          `grazing`,
          `feedlot`,
          `lambs`,
          `cattle`,
          `agriculture`,
          `jobs`,
        ]}
        title="Careers"
      />
      <section>
        <div className="flex flex-wrap items-center justify-between max-w-4xl p-4 mx-auto md:p-8">
          <div>
            <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Careers
            </h1>
            <p>
              At Emigh Livestock we are pushing the boundaries of combined cattle and sheep operations on a commercial level, with an energetic team full of creativity, passion, and collaboration. By blending the traditions of our 143 year history and innovating with new 21st century technologies we can efficiently raise the highest quality livestock in Northern California.
            </p>
            <h3 className="mt-2 leading-8 font-bold tracking-tight text-gray-900 sm:leading-10">
              Open Positions
            </h3>
            {data.jobs.nodes && data.jobs.nodes.length ? (<ul className="list-disc list-inside ml-5">
              {data.jobs.nodes.map((j) => (<li key={j.id}>
                <Link to={`${j.id}`}>{j.title} - {j.jobLocation}</Link>
              </li>
              ))}
            </ul>) : (<p className="mt-3 bg-gray-100 rounded-xl p-8">Currently there are no open positions</p>)
            }
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default JobsPage;
